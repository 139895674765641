import { Expose, Transform, Type } from 'class-transformer'
import { nextMonday } from 'date-fns'

import cohortMappings from '../../constants/cohortMappings'
import { CW_MARKER_ROLES } from '../../constants/roles'

export class ModuleStaff {
  login: string
  email: string
  firstname: string
  lastname: string
  @Expose({ name: 'role_in_department' })
  roleInDepartment: string

  get fullName(): string {
    return `${this.firstname} ${this.lastname}`
  }
}

export class ModuleHelper {
  login: string
  email: string
  firstname: string
  lastname: string
  role: string

  get fullName(): string {
    return `${this.firstname} ${this.lastname}`
  }
}

export class CohortRegulations {
  cohort: string

  @Expose({ name: 'pass_mark' })
  passMark: number
}

export class ModuleBase {
  code: string
  title: string
  terms: number[]
}

export class Module extends ModuleBase {
  @Expose({ name: 'applicable_cohorts' })
  applicableCohorts: string[]

  @Type(() => CohortRegulations)
  @Expose({ name: 'cohort_regulations' })
  cohortRegulations: CohortRegulations[]

  level?: number

  @Type(() => ModuleStaff)
  staff: ModuleStaff[]

  @Type(() => ModuleHelper)
  helpers: ModuleHelper[]
  roles?: string[]
}

export class EnrolledStudent {
  login: string
  email: string
  firstname: string
  lastname: string
  level: number
  status: string
  cohort: string

  get fullName(): string {
    return `${this.firstname} ${this.lastname}`
  }
}

export class UserDetails {
  login: string
  cid: string
  year: string
  email: string
  cohort: string
  firstname: string
  lastname: string

  @Expose({ name: 'degree_year' })
  degreeYear: string

  @Expose({ name: 'role_in_department' })
  roleInDepartment: string

  @Expose({ name: 'roles_in_department' })
  rolesInDepartment: string[]

  @Type(() => Module)
  modules: Module[]

  @Type(() => Module)
  @Expose({ name: 'modules_helped' })
  modulesHelped: Module[]

  @Expose({ name: 'has_extension_clearance' })
  hasExtensionClearance: boolean

  get isStaff(): boolean {
    return this.roleInDepartment === 'staff'
  }

  get isYearCoordinator(): boolean {
    // TODO: This is definitely a very broad check at the moment.
    return (this.rolesInDepartment || []).some((r) => r.includes('Coordinator'))
  }

  get isSeniorTutor(): boolean {
    return (this.rolesInDepartment || []).some((r) => r.includes('Senior Tutor'))
  }

  get isPHDStudent(): boolean {
    return this.roleInDepartment === 'phd'
  }

  get cohortName(): string | undefined {
    return cohortMappings?.[this.cohort]
  }

  isGTAForModule(module: string): boolean {
    return this.roleInDepartment === 'phd' && this.modulesHelped.map((m) => m.code).includes(module)
  }

  isStaffForModule(module: string): boolean {
    return this.roleInDepartment === 'staff' && this.modules.map((m) => m.code).includes(module)
  }

  isUTAForModule(module: string): boolean {
    return (
      this.roleInDepartment === 'student' &&
      !!this.modulesHelped.find((m) => m.code === module)?.roles?.includes('UTA Tutor')
    )
  }

  isGUTAForModule(module: string): boolean {
    return this.isGTAForModule(module) || this.isUTAForModule(module)
  }

  isGTAMarkerForModule(module: string): boolean {
    return (
      this.roleInDepartment === 'phd' &&
      !!this.modulesHelped
        .find((m) => m.code === module)
        ?.roles?.some((role) => CW_MARKER_ROLES.includes(role))
    )
  }

  isMarkerForModule(module: string): boolean {
    return this.isGTAMarkerForModule(module) || this.isUTAForModule(module)
  }

  isGUTAForAnyModule(): boolean {
    return this.modulesHelped.some((m) => m?.roles?.includes('UTA Tutor'))
  }

  isStaffOrTAForModule(module: string): boolean {
    return this.isStaff || this.isGUTAForModule(module)
  }

  get associatedModules(): Module[] {
    return [...this.modules, ...(this.modulesHelped || [])]
  }
}

export class TutorialGroup {
  number: number
  type: string
  tutor: Person
  uta: Person | null
  members: Person[]

  get groupLabel() {
    return `${this.type} ${this.number.toString().padStart(2, '0')}`
  }

  toString() {
    return this.groupLabel
  }

  isUta(login: string): Boolean {
    return this.uta?.login === login
  }

  isTutor(login: string): Boolean {
    return this.tutor.login === login
  }
}

export class Person {
  login: string
  firstname: string
  lastname: string
}

export class PersonalTutee extends Person {
  cohort: string
}

export class PersonalTutoringAllocation {
  @Type(() => Person)
  tutor: Person

  @Type(() => PersonalTutee)
  tutee: PersonalTutee

  get tuteeUsername(): string {
    return this.tutee.login
  }

  get tutorUsername(): string {
    return this.tutor.login
  }
}

/**
 * The start date's always a Monday and the end date's always a Friday
 * The weeks holds a count of the number of mondays in the term
 */
export class Term {
  name: string

  @Type(() => Date)
  @Transform(({ value }) => nextMonday(value), { toClassOnly: true })
  start: Date

  @Type(() => Date)
  end: Date
  weeks: number
}

export class StudentShortInfo {
  login: string
  firstname: string
  lastname: string
  cohort: string

  get fullName(): string {
    return `${this.firstname} ${this.lastname}`
  }
}
