import { format } from 'date-fns'
import React, { Fragment, useMemo, useState } from 'react'
import { Calendar2Check } from 'react-bootstrap-icons'
import { useNavigate, useParams } from 'react-router-dom'

import { Tabs } from '../components/Tabs'
import PageHeader from '../components/common/PageHeader'
import PersonalTutorMeetingDialog, {
  PersonalTutorMeetingPayload,
} from '../components/dialogs/PersonalTutorMeetingDialog'
import Select, { StandardSelectOption } from '../components/select/Select'
import PersonalTutorMeetingsTable from '../components/tables/PersonalTutorMeetingsTable'
import Tooltip from '../components/tooltip/Tooltip'
import { useUser } from '../contextManagers/user.context'
import { useAllStudents } from '../hooks/allStudents.service'
import { useOwnPersonalTutoringMeetings } from '../hooks/personaTutoring.service'
import { usePersonalTutoringAllocationsForTutor } from '../hooks/ptAllocations.service'
import { useTerms } from '../hooks/terms.service'
import { Banner, Button, Div, FlexContainer, Hr, Span } from '../styles/_app.style'
import {
  ActionItemContainer,
  ActionableListContainer,
  ListBlock,
  ListItem,
  ListItemContainer,
  ListItemMainText,
  ListItemSubText,
} from '../styles/list.style'
import { Main, Section } from '../styles/root.style'
import { PersonalTutee } from '../types/schemas/abc'
import { PersonalTutorMeeting } from '../types/schemas/tutoring'
import { groupByProperty, now } from '../utils'

const PastoralCare = () => {
  const { year } = useParams()
  const navigate = useNavigate()
  const { userDetails } = useUser()
  const { allStudents } = useAllStudents(year as string)
  const { tutoringAllocations, tutoringAllocationsAreLoaded } =
    usePersonalTutoringAllocationsForTutor(year as string, userDetails?.login as string)

  const [searchValue, setSearchValue] = useState<StandardSelectOption>()
  const searchOptions = useMemo(
    () =>
      allStudents.map((s) => ({
        value: s.login,
        label: `${s.fullName} (${s.login})`,
      })),
    [allStudents]
  )

  const tutees = useMemo(
    () =>
      tutoringAllocations
        .sort((a1, a2) => parseInt(a1.tutee.cohort.charAt(1)) - parseInt(a2.tutee.cohort.charAt(1)))
        .map((allocation) => allocation.tutee),
    [tutoringAllocations]
  )
  const { personalTutorMeetings, meetingsLoaded, actions } = useOwnPersonalTutoringMeetings(
    year as string
  )
  const latestMeetingLookup = useMemo(() => {
    let map = new Map()
    personalTutorMeetings.forEach((meeting: PersonalTutorMeeting) => {
      if (!map.has(meeting.tutee) || map.get(meeting.tutee).date.getTime() < meeting.date.getTime())
        map.set(meeting.tutee, meeting)
    })
    return map
  }, [personalTutorMeetings])
  const terms = useTerms()
  const currentTerm = useMemo(
    () => terms.find((term) => now() >= term.start && now() < term.end),
    [terms]
  )

  function onSearchConfirmation() {
    if (!searchValue || !year) return
    navigate(`/${year}/students/${searchValue.value}`)
  }

  function logNewMeeting(tutee: string) {
    actions.recordMeeting(tutee, `${currentTerm?.name || 'Unscheduled'} catch up`)
  }

  function tuteeItemGenerator(tutee: PersonalTutee) {
    return (
      <ListItemContainer size="lg" css={{ justifyContent: 'space-between' }}>
        <ListItem>
          <ListItemMainText>
            {`${tutee.firstname} ${tutee.lastname} (${tutee.login})`}
          </ListItemMainText>
          <ListItemSubText>
            {latestMeetingLookup.has(tutee.login)
              ? `Last met on ${format(
                  latestMeetingLookup.get(tutee.login).date,
                  'EEEE, LLL d yyyy'
                )}`
              : 'No meetings recorded yet.'}
          </ListItemSubText>
        </ListItem>
        <Span css={{ color: '$lowContrast' }}>{tutee.cohort}</Span>
      </ListItemContainer>
    )
  }

  function hrefGenerator(tutee: PersonalTutee) {
    return `../students/${tutee.login}`
  }

  const TuteesList = () => {
    const tuteesByCohort = groupByProperty(tutees, 'cohort', 'login', true)
    const lastIndex = Object.keys(tuteesByCohort).length - 1
    return (
      <ActionableListContainer css={{ flexDirection: 'column' }}>
        {Object.entries(tuteesByCohort).map(([_, tuteesForCohort], index) => (
          <Fragment key={index}>
            <Div css={{ display: 'flex', flexDirection: 'row' }}>
              <ListBlock>
                <Tabs
                  attribute={'login'}
                  data={tuteesForCohort}
                  generator={tuteeItemGenerator}
                  href={hrefGenerator}
                />
              </ListBlock>
              <Div css={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                {tuteesForCohort.map((tutee) => (
                  <ActionItemContainer size="lg" key={tutee.login}>
                    <Tooltip label={'Log meeting'}>
                      <Button type="button" icon onClick={() => logNewMeeting(tutee.login)}>
                        <Calendar2Check size={22} />
                      </Button>
                    </Tooltip>
                  </ActionItemContainer>
                ))}
              </Div>
            </Div>
            {index < lastIndex && <Hr />}
          </Fragment>
        ))}
      </ActionableListContainer>
    )
  }

  const TuteesTab = () => {
    return (
      <>
        {tutees.length > 0 ? (
          <TuteesList />
        ) : (
          <Banner>
            {tutoringAllocationsAreLoaded ? 'No tutees to show yet.' : 'Loading tutees...'}
          </Banner>
        )}
      </>
    )
  }

  const PTMeetingsTab = () => {
    const [meetingToEdit, setMeetingToEdit] = useState<PersonalTutorMeeting | undefined>()
    const [editDialogOpen, setEditDialogOpen] = useState(false)

    function openEditDialog(meetingID: number) {
      setMeetingToEdit(personalTutorMeetings.find((m) => m.id === meetingID))
      setEditDialogOpen(true)
    }

    function editMeeting(payload: PersonalTutorMeetingPayload) {
      if (meetingToEdit) {
        actions.editMeeting(meetingToEdit.id, payload)
        setMeetingToEdit(undefined)
      }
    }

    return personalTutorMeetings.length > 0 ? (
      <>
        <div style={{ overflowX: 'scroll' }}>
          <PersonalTutorMeetingsTable
            meetings={personalTutorMeetings}
            handleDelete={actions.deleteMeeting}
            handleEdit={openEditDialog}
          />
        </div>
        <PersonalTutorMeetingDialog
          meeting={meetingToEdit}
          open={editDialogOpen}
          onOpenChange={setEditDialogOpen}
          onSubmit={editMeeting}
        />
      </>
    ) : (
      <Banner>{meetingsLoaded ? 'No meetings to show yet.' : 'Loading meetings...'}</Banner>
    )
  }

  // TABS =============================
  const TABS = ['Tutees', 'PT Meetings']
  const [currentTab, setCurrentTab] = useState(TABS[0])

  return (
    <>
      <Main>
        <PageHeader title="Pastoral care" />
        <Section>
          <FlexContainer column style={{ gap: '1rem' }}>
            <FlexContainer style={{ gap: '1rem', flexWrap: 'wrap' }}>
              <Select
                isSearchable
                options={searchOptions}
                placeholder={'Search for any student...'}
                onChange={(v: any) => setSearchValue(v)}
              />
              <Tooltip label={'Look up student'}>
                <Button disabled={!searchValue} onClick={onSearchConfirmation}>
                  Go
                </Button>
              </Tooltip>
            </FlexContainer>
            <FlexContainer style={{ gap: '1rem', flexWrap: 'wrap' }}>
              {TABS.map((tab) => (
                <Button
                  key={tab}
                  css={{ minWidth: '9rem', flex: '1 1 9rem', margin: 0 }}
                  active={currentTab === tab}
                  onClick={() => setCurrentTab(tab)}
                  animate
                >
                  {tab}
                </Button>
              ))}
            </FlexContainer>
          </FlexContainer>
        </Section>
        <Section>{currentTab === 'Tutees' ? <TuteesTab /> : <PTMeetingsTab />}</Section>
      </Main>
    </>
  )
}

export default PastoralCare
