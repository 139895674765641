import {
  RowSelectionState,
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { format, isSameDay } from 'date-fns'
import { useEffect, useState } from 'react'
import { Check, Dash } from 'react-bootstrap-icons'

import { Button, Checkbox, Container, Indicator } from '../../styles/_app.style'
import { Control, Field, Form, Input } from '../../styles/form.style'
import { Term } from '../../types/schemas/abc'
import { Attendance, Session } from '../../types/schemas/tutoring'
import { tutorialSessionTitle } from '../../utils'
import Dialog from '../dialogs/Dialog'
import { AttendanceStatus, ComputedStatusMarker } from '../records/ComputedStatusMarker'
import Tooltip from '../tooltip/Tooltip'
import GenericTanStackTableRenderer from './tableRenderer/GenericTanStackTableRenderer'

const DATETIME_LOCAL_FORMAT = "yyyy-MM-dd'"

const TutorialWeekAttendance = ({
  session,
  terms,
  onSessionUpdate,
  deleteSession,
  studentsLookup,
}: {
  session: Session
  terms: Term[]
  onSessionUpdate: (sessionId: number, payload: Session) => void
  deleteSession: (sessionId: number) => void
  studentsLookup: Map<string, any>
}) => {
  const columnHelper = createColumnHelper<Attendance>()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [columnVisibility, setcolumnVisibility] = useState<VisibilityState>({
    checkbox: false,
  })

  const columns = [
    columnHelper.display({
      id: 'checkbox',
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(checkedState) => table.toggleAllRowsSelected(!!checkedState)}
        >
          <Indicator>
            {table.getIsAllRowsSelected() ? <Check size={22} /> : <Dash size={22} />}
          </Indicator>
        </Checkbox>
      ),
      cell: ({ row }) => (
        <Checkbox
          css={{ display: 'block' }}
          checked={row.getIsSelected()}
          onCheckedChange={row.getToggleSelectedHandler()}
        >
          <Indicator>
            <Check size={22} />
          </Indicator>
        </Checkbox>
      ),
      meta: {
        textIsCentred: true,
        css: {
          // make columns a little more even
          width: '28px',
          whiteSpace: 'nowrap',
          paddingRight: '1.25rem',
          boxSizing: 'content-box',
        },
      },
    }),
    columnHelper.accessor(
      ({ username }) =>
        `${studentsLookup.get(username)?.firstname} ${
          studentsLookup.get(username)?.lastname
        } (${username})`,
      {
        cell: (info) => `${info.getValue()}`,
        id: 'student',
        header: 'Student',
      }
    ),
    columnHelper.accessor(({ present }) => present, {
      id: 'status',
      cell: (info) => <ComputedStatusMarker status={AttendanceStatus.fromValue(info.getValue())} />,
      header: 'Status',
      meta: {
        textIsCentred: true,
        css: {
          width: '7.5rem',
        },
      },
    }),
  ]

  const table = useReactTable({
    data: session.attendances,
    columns,
    enableMultiRowSelection: true,
    enableRowSelection: true,
    enableHiding: true,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      columnVisibility,
    },
    onColumnVisibilityChange: setcolumnVisibility,
    onRowSelectionChange: setRowSelection,
  })

  // Auto select rows NOT marked absent
  useEffect(() => {
    table
      .getRowModel()
      .rows.forEach((row) => row.original.present !== false && row.toggleSelected())
  }, [])

  const handleSaveOrEdit = () => {
    if (columnVisibility.checkbox) {
      // Save
      const updatedAttendanceData = session.attendances.map((student, studentIndex) => {
        const updatedStudent = { ...student }
        updatedStudent.present = rowSelection[studentIndex] ?? false
        return updatedStudent
      })
      setcolumnVisibility({ checkbox: false })

      onSessionUpdate(session.id, { ...session, attendances: updatedAttendanceData })
    } else {
      // Show checkboxes for editing
      setcolumnVisibility({ checkbox: true })
    }
  }

  const [showDeleteDialog, setshowDeleteDialog] = useState(false)

  // If any row has status "NO_STATUS" auto mark for editing
  useEffect(() => {
    if (session.attendances.some(({ present }) => typeof present === 'undefined')) {
      setcolumnVisibility({ checkbox: true })
    }
  }, [])

  return (
    <Container center stripPadding css={{ gap: '1.31rem' }}>
      <GenericTanStackTableRenderer
        table={table}
        style={{ table: { tableLayout: 'fixed', width: '95%' } }}
        size="wide"
        inlineHeader
      />
      <div
        style={{
          paddingLeft: '1rem',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Form
          onSubmit={(event) => {
            event.preventDefault()
          }}
        >
          <Field name="tutorialDate" inline css={{ fontSize: '$sm' }}>
            <Control asChild>
              <Input
                type="date"
                value={format(session.date, DATETIME_LOCAL_FORMAT)}
                onChange={({
                  target: {
                    value,
                    validity: { valid },
                  },
                }) => {
                  const parsedDate = new Date(Date.parse(value))
                  if (valid && !isSameDay(parsedDate, session.date)) {
                    onSessionUpdate(session.id, {
                      ...session,
                      date: parsedDate,
                    })
                  }
                }}
                required
              />
            </Control>
          </Field>
        </Form>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Tooltip label={'Delete session record'}>
            <Button
              onClick={() => setshowDeleteDialog(true)}
              css={{ opacity: columnVisibility.checkbox ? 1 : 0 }}
            >
              Delete
            </Button>
          </Tooltip>
          <Tooltip label={`${columnVisibility.checkbox ? 'Save' : 'Edit'} attendances for session`}>
            <Button onClick={handleSaveOrEdit}>
              {columnVisibility.checkbox ? 'Save' : 'Edit'}
            </Button>
          </Tooltip>
        </div>
      </div>

      <Dialog
        title={`Are you sure you want to delete the session for "${tutorialSessionTitle(
          session.date,
          terms
        )}"?`}
        primaryButtonText={'Delete'}
        secondaryButtonText={'Cancel'}
        onPrimaryClick={() => deleteSession(session.id)}
        onOpenChange={() => setshowDeleteDialog(false)}
        open={showDeleteDialog}
      />
    </Container>
  )
}

export default TutorialWeekAttendance
