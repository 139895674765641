import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { FormattedMark } from '../components/FormattedMark'
import { Tabs } from '../components/Tabs'
import ExerciseButton from '../components/exercise/ExerciseButtons'
import { FeedbackLink } from '../components/exercise/FeedbackButton'
import GenericTanStackTableRenderer from '../components/tables/tableRenderer/GenericTanStackTableRenderer'
import titles from '../constants/titles'
import { useUser } from '../contextManagers/user.context'
import { useMyExercises } from '../hooks/exercises.service'
import { Banner, Button, Footnote, Span } from '../styles/_app.style'
import { styleForExercise } from '../styles/exerciseItem.style'
import { ListBlock, ListItem, ListItemContainer, ListItemMainText } from '../styles/list.style'
import { Section } from '../styles/root.style'
import { Exercise } from '../types/schemas/emarking'
import { calculateGrade, displayTimestamp, isNumber } from '../utils'

const Exercises = () => {
  const { userDetails } = useUser()
  const { year, moduleCode } = useParams()
  const moduleTitle = userDetails?.associatedModules.find((m) => m.code === moduleCode)?.title
  let singletonModuleList = useMemo(() => [moduleCode as string], [moduleCode])
  const { exercises } = useMyExercises(year as string, singletonModuleList)
  const cwComponentTotal = useMemo(
    () =>
      exercises
        .filter((e) => e.isAssessed)
        .map((e) => e.weight)
        .reduce((a, b) => a + b, 0),
    [exercises]
  )

  function ExerciseCell(exercise: Exercise) {
    return (
      <ExerciseButton
        exercise={exercise}
        subtextChild={displayTimestamp(exercise.deadline)}
        css={styleForExercise(exercise, {
          allowRedBorder: !userDetails?.isStaffOrTAForModule(exercise.moduleCode),
        })}
      />
    )
  }

  const columnHelper = createColumnHelper<Exercise>()
  const columns = [
    columnHelper.accessor((row) => row, {
      id: 'exercise',
      cell: (info) => ExerciseCell(info.getValue()),
      header: () => 'Exercise',
    }),
    columnHelper.accessor((row) => row, {
      id: 'mark',
      cell: (info) => {
        let exercise = info.getValue()
        let mark = exercise.mark
        return mark && isNumber(mark.mark) ? (
          <FormattedMark mark={mark.mark} cap={mark.cap} maximumMark={exercise.maximumMark} />
        ) : null
      },
      header: () => 'Mark',
    }),
    columnHelper.accessor((row) => row, {
      id: 'grade',
      cell: (info) => {
        let exercise = info.getValue()
        let mark = exercise.mark
        return mark && isNumber(mark.mark) ? (
          <b>{calculateGrade(mark.mark, exercise.maximumMark)}</b>
        ) : null
      },
      header: () => 'Grade',
    }),
    columnHelper.accessor((row) => row, {
      id: 'feedback',
      cell: (info) => {
        let exercise = info.getValue()
        let feedback = exercise.feedback
        return feedback ? (
          <FeedbackLink
            css={{ fontSize: '$md' }}
            feedback={feedback}
            exerciseTitle={exercise.title}
          />
        ) : null
      },
      header: () => 'Feedback',
    }),
  ]

  const table = useReactTable({
    data: exercises,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  function exerciseItemGenerator(exercise: Exercise) {
    return (
      <ListItemContainer css={{ justifyContent: 'space-between' }}>
        <ListItem>
          <ListItemMainText>{exercise.fullTitle}</ListItemMainText>
        </ListItem>
        <Span css={{ color: '$lowContrast' }}>{exercise.moduleContribution}</Span>
      </ListItemContainer>
    )
  }

  function exerciseHrefGenerator(exercise: Exercise) {
    return `/${year}/modules/${exercise.moduleCode}/exercises/${exercise.number}`
  }

  function exercisePropsGenerator(exercise: Exercise) {
    return {
      css: {
        ...styleForExercise(exercise, {
          allowRedBorder: !userDetails?.isStaffOrTAForModule(exercise.moduleCode),
        }),
        margin: '0.25rem 0 0.25rem 0',
      },
    }
  }

  return (
    <>
      <Helmet>
        <title>{titles.exercises(year, moduleCode, moduleTitle)}</title>
      </Helmet>
      <Section>
        {userDetails?.isStaff && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginBottom: '0.5rem',
            }}
          >
            {/* RE the empty <span>: after suitable migration to using plain CSS/SCSS, use the ::before pseudoelement
             * to style a flexible spacer.
             */}
            {exercises.length > 0 && cwComponentTotal !== 100 ? (
              <Span css={{ color: '$red9' }}>Exercise weights must add up to 100</Span>
            ) : (
              <span></span>
            )}
            <Button>
              <RouterLink to={'new'}>New exercise</RouterLink>
            </Button>
          </div>
        )}
        {exercises.length === 0 ? (
          <Banner>No exercises to show</Banner>
        ) : userDetails?.isStaff || userDetails?.isGTAForModule(moduleCode!) ? (
          <ListBlock>
            <Tabs
              data={exercises}
              tabProps={exercises.map(exercisePropsGenerator)}
              generator={exerciseItemGenerator}
              href={exerciseHrefGenerator}
            />
          </ListBlock>
        ) : (
          <GenericTanStackTableRenderer table={table} showHeader={false} />
        )}
      </Section>
      {userDetails?.isStaff && (
        <Section>
          <Footnote center muted>
            CW component total: {cwComponentTotal}/100
          </Footnote>
        </Section>
      )}
    </>
  )
}

export default Exercises
