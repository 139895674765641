import { format, isValid } from 'date-fns'
import React, { useEffect, useState } from 'react'

import { Button } from '../../styles/_app.style'
import {
  Control,
  Field,
  Input,
  Label,
  LabelContainer,
  Message,
  Submit,
} from '../../styles/form.style'
import { PersonalTutorMeeting } from '../../types/schemas/tutoring'
import Dialog from './Dialog'

export interface PersonalTutorMeetingPayload {
  meetingDate: Date
  label: string
}

const PersonalTutorMeetingDialog = ({
  meeting,
  open,
  onOpenChange,
  onSubmit,
}: {
  meeting?: PersonalTutorMeeting
  open: boolean
  onOpenChange: (_: boolean) => void
  onSubmit: (payload: PersonalTutorMeetingPayload) => void
}) => {
  const DATETIME_LOCAL_FORMAT = 'yyyy-MM-dd'
  const initialData = {
    meetingDate: new Date(),
    label: '',
  }
  const [date, setDate] = useState(format(new Date(), DATETIME_LOCAL_FORMAT))
  const [formData, setFormData] = useState<PersonalTutorMeetingPayload>(initialData)
  useEffect(() => {
    if (!!meeting) {
      setDate(format(meeting.date, DATETIME_LOCAL_FORMAT))
      setFormData({ meetingDate: meeting.date, label: meeting.label })
    }
  }, [meeting])

  function applyPatch(
    key: keyof PersonalTutorMeetingPayload,
    value: PersonalTutorMeetingPayload[typeof key]
  ) {
    setFormData((current) => ({ ...current, [key]: value }))
  }

  function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDate(e.target.value)
    if (isValid(date)) applyPatch('meetingDate', new Date(Date.parse(e.target.value)))
  }

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      onPrimaryClick={() => onSubmit(formData)}
      title="Edit Personal Tutor Meeting"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Field name="date">
          <LabelContainer>
            <Label asChild>Meeting Date</Label>
            <Message match="valueMissing">A date is required</Message>
          </LabelContainer>
          <Control asChild>
            <Input type="date" value={date} onChange={handleDateChange} required />
          </Control>
        </Field>
        <Field name="label">
          <LabelContainer>
            <Label asChild>Meeting label</Label>
            <Message match="valueMissing">Label Required</Message>
          </LabelContainer>
          <Control asChild>
            <Input
              type="text"
              value={formData.label}
              onChange={({ target: { value } }) => applyPatch('label', value)}
              required
            />
          </Control>
        </Field>
        <Submit asChild>
          <Button>Submit</Button>
        </Submit>
      </div>
    </Dialog>
  )
}

export default PersonalTutorMeetingDialog
